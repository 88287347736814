body {
    /*font-family: Roboto, sans-serif;*/
    /*font-family: navigo;*/
}

.checkbox-note {
    margin-left: 24px;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    color: red;
}

@media (min-width: 768px) {
    .col-fixed {
        position: fixed;
        margin-right: 20px;
    }
}

div.heading-bar {
    height: 35px;
    width: 100%;
    background-color: #0895a9;
    padding: 7px 8px 0 8px;
    box-shadow: 0 8px 8px -4px #173753;
    margin-bottom: 20px;
}

a.heading-bar {
    text-transform: uppercase;
    color: white;
    text-decoration: none;
    font-size: 15px;
    /*font-family: Roboto, sans-serif;*/
    /*font-family: navigo;*/
    font-weight: 400;
    padding-right: 30px;
}

img.heading {
    width: 110px;
    margin: 0 0 20px 20px;
}

.return-icon {
    color: #2ecc71;
    margin-right: 10px;
    font-size: 16px;
}