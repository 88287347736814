ul.notes {
    list-style: none;
    text-indent: -34px;
}

ul.notes > li {
    margin-bottom: 8px;
}

.disclaimer-icon {
    font-size: 16px;
    color: darkorange;
    margin: 0 8px 0 8px;
}

.discount-icon {
    font-size: 16px;
    color: limegreen;
    margin: 0 8px 0 8px;
}

.non-profit-discount-icon {
    font-size: 16px;
    color: red;
    margin: 0 8px 0 8px;
}

.regular-price-strikethrough {
    text-decoration: line-through;
    color: gray;
}